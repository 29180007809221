import React, { useState } from "react";

const Form = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            fname,
            lname
        };

        fetch('https://mysqltest.madushiranasooriyaportfolio.com/api/sendData.php', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.message);
            // Reset form or perform any necessary action
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="fname">First Name:</label>
            <input type="text" name="fname" id="fname" value={fname} onChange={(e) => setFname(e.target.value)} />
            <label htmlFor="lname">Last Name:</label>
            <input type="text" name="lname" id="lname" value={lname} onChange={(e) => setLname(e.target.value)} />
            <button type="submit">Submit</button>
        </form>
    )
}

export default Form;
